import { Pagination } from '@/api/pagination';
import { of, Observable, async } from 'rxjs';
import { map } from 'rxjs/operators';
import { cepApi } from '@/api/cep.api';

const api = new cepApi();


const searchCEP = (cep:string): Observable<any> => {
    return api.searchCEP(cep).pipe(map((data: any) => data))
}

export default {
    searchCEP
}
