<template>
  <v-form
    ref="form"
    lazy-validation
    @submit.prevent="onSubmit"
    class="app-form mt-4"
  >
    <v-row>
      <v-col md="6" sm="12" xs="12" lg="6">
        <v-text-field
          label="Nome *"
          placeholder="Digite o nome da empresa"
          outlined
          small
          color="success"
          v-model="empresaForm.name"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12" xs="12" lg="6">
        <v-text-field
          label="CNPJ *"
          placeholder="Digite o cnpj da empresa"
          outlined
          small
          color="success"
          v-model="empresaForm.cnpj"
          :rules="[rules.required]"
          v-mask="['##.###.###/####-##']"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12" xs="12" lg="8" class="pt-0">
        <v-text-field
          label="Email *"
          placeholder="Digite o email da empresa"
          outlined
          small
          color="success"
          v-model="empresaForm.email"
          :rules="[rules.required, rules.email]"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12" xs="12" lg="4" class="pt-0">
        <v-text-field
          label="Telefone *"
          placeholder="Digite o telefone da empresa"
          outlined
          small
          color="success"
          v-model="empresaForm.cell_phone"
          :rules="[rules.required]"
          v-mask="['(##) #####-####']"
        ></v-text-field>
      </v-col>
      <v-divider class="mb-5"></v-divider>
      <v-col md="12" sm="12" xs="12" lg="12" class="pt-0">
        <v-text-field
          label="CEP *"
          placeholder="Digite o CEP*"
          outlined
          small
          color="success"
          v-model="cep"
          :rules="[rules.required]"
          v-mask="['#####-###']"
          @keyup="handlerInputCep()"
          :loading="loader"
          :messages="msg"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12" xs="12" lg="6" class="pt-0">
        <v-text-field
          label="Rua *"
          placeholder="Digite o nome da rua*"
          outlined
          small
          color="success"
          v-model="empresaForm.address.address"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col md="2" sm="12" xs="12" lg="2" class="pt-0">
        <v-text-field
          label="Número *"
          placeholder="Digite o número*"
          outlined
          small
          color="success"
          v-model="empresaForm.address.number"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col md="4" sm="12" xs="12" lg="4" class="pt-0">
        <v-text-field
          label="Complemento"
          placeholder="Digite o complemento"
          outlined
          small
          color="success"
          v-model="empresaForm.address.complement"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12" xs="12" lg="6" class="pt-0">
        <v-text-field
          label="Bairro *"
          placeholder="Digite o bairro*"
          outlined
          small
          color="success"
          v-model="empresaForm.address.district"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col md="6" sm="12" xs="12" lg="6" class="pt-0">
        <v-text-field
          label="Cidade *"
          placeholder="Digite a cidade*"
          outlined
          small
          color="success"
          v-model="empresaForm.address.city"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-btn
        color="warning"
        outlined
        depressed
        type="button"
        @click="$router.push({ name: `login` })"
        >Voltar para Login</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="success" depressed type="submit">Continuar</v-btn>
    </v-card-actions>
    <section class="loading" v-if="form.isLoading">
      <v-progress-circular indeterminate color="success"></v-progress-circular>
      <span>Enviando informações...</span>
    </section>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CepService from "@/services/cep.service";

export default {
  name: "CadastroEmpresaForm",
  data: () => ({
    empresaForm: {
      name: null,
      cnpj: null,
      email: null,
      cell_phone: null,
      address: {},
    },
    rules: {
      required: (value) => !!value || "Esse campo deve ser preenchido",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail inválido.";
      },
    },
    loader: false,
    msg: "",
    cep: "",
  }),
  methods: {
    onSubmit() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.createCompany(this.empresaForm);
      }
    },
    clearEmpresaForm() {
      this.empresaForm = {
        name: null,
        cnpj: null,
        email: null,
      };
    },
    handlerInputCep() {
      if (this.cep.length === 9) {
        this.loader = true;
        this.msg = "Pesquisando";
        this.searchCEP();
      }
    },
    async searchCEP() {
      try {
        const { cep, city, neighborhood, service, state, street } =
          await CepService.searchCEP(this.cep).toPromise();
        this.loader = false;
        this.msg = "Endereço encontrado";
        this.empresaForm.address = {
          address: street,
          district: neighborhood,
          city: city,
          state: state,
          zipcode: cep,
        };
      } catch (err) {
        this.loader = false;
        this.msg = "";
      }
    },
    ...mapActions("cadastroEmpresa", { createCompany: "createCompany" }),
  },
  computed: {
    ...mapState("cadastroEmpresa", ["form"]),
  },
  watch: {
    // verifica se muda o step para limpar os campos do form
    "form.currentStep": function (newValue, oldValue) {
      if (newValue) {
        this.clearEmpresaForm();
      }
    },
  },
};
</script>
