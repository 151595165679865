<template>
  <v-layout align-center justify-center>
    <v-flex xs11 sm12 md8 lg8>
      <app-snack-bar></app-snack-bar>
      <v-card class="app-card">
        <v-card-title class="app-card-title">Cadastro de Empresa</v-card-title>
        <v-card-text class="app-card-content">
          <div class="layout column align-center">
            <img src="../../assets/images/logo.svg" alt="Logo Sistransce" class="logo" />
          </div>
          <v-alert text dense color="info" icon="info" border="left">
            Após finalizar o cadastro abaixo entraremos em contato informando o
            andamento da solicitação e próximos passos.
          </v-alert>
          <v-divider></v-divider>
          <v-alert type="warning" class="mt-2" v-if="form.error">
            {{
            form.error
            }}
          </v-alert>
          <v-stepper outlined alt-labels class="app-stepper" v-model="form.currentStep">
            <v-stepper-header class="app-stepper-header">
              <v-stepper-step
                step="1"
                class="app-stepper-header__step"
                color="success"
                :complete="form.currentStep > 1"
              >Empresa</v-stepper-step>
              <v-spacer></v-spacer>
              <v-stepper-step
                step="2"
                class="app-stepper-header__step"
                color="success"
                :complete="form.currentStep > 2"
                :editable="form.currentStep === 3"
              >Documentos</v-stepper-step>
              <v-spacer></v-spacer>
              <v-stepper-step
                step="3"
                class="app-stepper-header__step"
                color="success"
                :complete="form.currentStep > 3"
              >Pagamento</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <EmpresaForm />
              </v-stepper-content>
              <v-stepper-content step="2">
                <DocumentosForm />
              </v-stepper-content>
              <v-stepper-content step="3">
                <PagamentoForm />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import EmpresaForm from "./EmpresaForm";
import DocumentosForm from "./DocumentosForm";
import PagamentoForm from "./PagamentoForm";
import GlobalStore from "@/store/modules/global";
import AppSnackBar from "@/components/AppSnackBar";

import { APP_PREFIX_AUTH } from "@/commons/config"

const LOCALSAVE = `${APP_PREFIX_AUTH}@cadastroEmpresa`

export default {
  name: "CadastroEmpresa",
  components: {
    EmpresaForm,
    DocumentosForm,
    PagamentoForm,
    AppSnackBar
  },
  methods: {
    ...mapActions("cadastroEmpresa", {
      clearForm: "clearForm"
    }),
    ...mapMutations("cadastroEmpresa", {setForm: "SET_INITIAL_STATE"})
  },
  computed: {
    ...mapState("cadastroEmpresa", ["form"])
  },
  mounted(){
    const localStorageItem = JSON.parse(localStorage.getItem(LOCALSAVE))
    if(localStorageItem) {
      this.setForm(localStorageItem.form)
    }
  },
  destroyed() {
    this.clearForm();
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 275px;
  margin: 0px auto 20px auto;
  display: table;
}
</style>
