import { AxiosObservable } from 'axios-observable';
import { BaseAPI } from './base.api';
import { API_CEP } from '@/commons/config';


export class cepApi extends BaseAPI<any> {
    protected apiUrlCep: String;

    constructor(){
        super()
        this.apiUrlCep = API_CEP
    }

    public searchCEP(cep:string):AxiosObservable<any>{
       return this.axios.get(`${this.apiUrlCep}/api/cep/v1/${cep}`)
    }
}